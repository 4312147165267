<template>
  <v-chart :options="options" auto-resize></v-chart>
</template>

<script>
export default {
  props: {
    type: {
      default: "line",
      type: String
    },
    current: {
      type: Array,
      required: false
    },
    previous: {
      type: Array,
      required: false
    }
  },
  computed: {
    series() {
      let series = [];
      if (this.current) {
        series.push({
          name: "Current",
          type: this.type,
          smooth: true,
          xAxisIndex: 0,
          data: this.current,
          itemStyle: {
            color: this.$theme.accent
          },
          zlevel: 1001
        });
      }

      if (this.previous) {
        series.push({
          name: "Previous",
          type: this.type,
          smooth: true,
          xAxisIndex: 1,
          data: this.previous,
          itemStyle: {
            color: this.$theme.greytxt
          },
          zlevel: 1000
        });
      }

      return series;
    },
    dateFormat() {
      let format = "YYYY-MM-DD";

      if (this.current.length > 2) {
        let date1 = this.$moment.parseZone(this.current[0][0]).utc();
        let date2 = this.$moment.parseZone(this.current[1][0]).utc();

        // If not grouped per hour
        if (date1.format("HH") !== date2.format("HH")) {
          format += " HH:mm";
        }
      }

      return format;
    },
    options() {
      let _this = this;

      return {
        useUTC: true,
        tooltip: {
          trigger: "axis",
          formatter: value => {
            let tooltip = "";

            value.forEach((serie, index) => {
              let date = this.$moment.parseZone(serie.data[0]).utc();
              let amount = serie.data[1];

              // tooltip += serie.marker + date.format('YYYY-MM-DD HH:mm') + '<br/>' + amount;
              tooltip +=
                serie.marker + date.format(_this.dateFormat) + "<br/>" + amount;

              let addNewLine = value.length - index;

              if (addNewLine > 1 && addNewLine !== index) {
                tooltip += '<hr class="mt-2 mb-2">';
              }
            });

            return tooltip;
          }
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          top: "24px",
          containLabel: true
        },
        xAxis: [
          {
            type: "time",
            boundaryGap: true,
            splitNumber: 2,
            axisLabel: {
              formatter: value => {
                return this.$moment(value)
                  .utc()
                  .format("HH");
              }
            },
            // zlevel: 10
            z: 10,
            show: false
          },
          {
            type: "time",
            boundaryGap: false,
            splitNumber: 2,
            axisLabel: {
              formatter: value => {
                return this.$moment(value)
                  .utc()
                  .format("HH");
              }
            },
            show: false
          }
        ],
        yAxis: [
          {
            name: "",
            type: "value",
            position: "left",
            axisLine: {
              show: false
            },
            splitNumber: 3
          }
        ],
        series: this.series
      };
    }
  }
};
</script>

<style scoped>
.v-card .echarts {
  width: 100%;
  height: 100%;
  min-height: 200px;
}
</style>
