<template>
  <v-chart :options="options" :auto-resize="true"></v-chart>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
export default {
  props: {
    type: {
      default: "bar",
      type: String
    },
    value: {
      type: Array,
      required: false
    }
  },
  filters: { local_numbers },
  computed: {
    series() {
      let series = [];

      series.push({
        name: "",
        type: this.type,
        smooth: true,
        xAxisIndex: 0,
        data: this.value,
        itemStyle: {
          color: this.$theme.primary,
          emphasis: {
            color: `${this.$theme.primary}ED`
          }
        },
        zlevel: 1001
      });

      return series;
    },
    options() {
      let _this = this;

      return {
        tooltip: {
          trigger: "axis",
          formatter: value => {
            let tooltip = "";

            value.forEach((serie, index) => {
              let date = this.$moment.parseZone(serie.data[0]).utc();
              let amount = serie.data[1];

              // tooltip += serie.marker + date.format('YYYY-MM-DD HH:mm') + '<br/>' + amount;
              tooltip +=
                serie.marker +
                date.format("YYYY MMMM") +
                "<br/>" +
                _this.$options.filters.local_numbers(amount);

              let addNewLine = value.length - index;

              if (addNewLine > 1 && addNewLine !== index) {
                tooltip += '<hr class="mt-2 mb-2">';
              }
            });

            return tooltip;
          }
        },
        // legend: {
        //     data:['Klik','Salg','Omsætning','Kommission'],
        //     selected: {
        //         'Klik': true,
        //         'Salg': true,
        //         'Omsætning': false,
        //         'Kommission': false
        //     }
        // },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: true,
            axisLabel: {
              formatter: value => {
                return this.$moment(value).format("MMM YY");
              }
            },
            // zlevel: 10
            z: 10
          }
        ],
        yAxis: [
          {
            name: "",
            type: "value",
            position: "left"
            // show: false,
          }
        ],
        series: this.series
      };
    }
  }
};
</script>

<style scoped>
.v-card .echarts {
  width: 100%;
  height: auto;
  min-height: 400px;
}
</style>
