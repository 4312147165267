<template>
  <v-card>
    <v-card-text>
      <v-container class="p-3 pb-0">
        <v-layout row wrap>
          <v-flex xs6 class="text-left">
            <v-icon x-large color="primary">{{ icon }}</v-icon>
          </v-flex>
          <v-flex xs6>
            <div class="headline font-weight-bold d-flex justify-end">
              <div class="d-flex body-2 align-center">
                <v-icon small v-if="isNegative" color="error" class="mr-2"
                  >fal fa-arrow-down</v-icon
                >
                <v-icon small v-if="isPositive" color="success" class="mr-2"
                  >fal fa-arrow-up</v-icon
                >
                <div
                  class="mr-2"
                  v-if="percentage"
                  :class="{
                    'error--text': isNegative,
                    'success--text': isPositive
                  }"
                >
                  {{ percentage }}%
                </div>
                <div class="title font-weight-bold">{{ total }}</div>
              </div>
            </div>
            <div class="subheading text-right">{{ title }}</div>
          </v-flex>
        </v-layout>
      </v-container>
      <div style="max-height: 300px;">
        <current-versus-previous-chart
          :current="current"
          :previous="previous"
        ></current-versus-previous-chart>
      </div>
      <v-layout row fill-height class="text-center">
        <v-flex>
          <div class="title text--secondary">{{ predicted }}</div>
          <div class="text--secondary">Expected</div>
        </v-flex>
        <v-flex v-if="conversion !== null">
          <div class="title text--secondary">{{ conversion }}</div>
          <div class="text--secondary">Conversion</div>
        </v-flex>
        <v-flex v-if="roi !== null">
          <div class="title text--secondary">
            {{ roi !== 0 ? Number(roi.toFixed(2)) : roi }}
          </div>
          <div class="text--secondary">ROI</div>
        </v-flex>
        <v-flex v-if="cpc !== null">
          <div class="title text--secondary">
            {{ cpc !== 0 ? Number(cpc.toFixed(2)) : cpc }}
          </div>
          <div class="text--secondary">CPC</div>
        </v-flex>
        <v-flex v-if="epc !== null">
          <div class="title text--secondary">
            {{ epc !== 0 ? Number(epc.toFixed(2)) : epc }}
          </div>
          <div class="text--secondary">EPC</div>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>
<script>
import CurrentVersusPreviousChart from "../../charts/CurrentVersusPreviousChart";
export default {
  components: { CurrentVersusPreviousChart },
  props: {
    icon: {
      type: String,
      required: true,
      default: ""
    },
    title: {
      type: String,
      required: true
    },
    total: {
      type: [Number, String],
      required: true
    },
    predicted: {
      type: [Number, String],
      required: true
    },
    conversion: {
      type: [Number, String],
      required: false,
      default: null
    },
    roi: {
      type: [Number, String],
      required: false,
      default: null
    },
    cpc: {
      type: [Number, String],
      required: false,
      default: null
    },
    epc: {
      type: [Number, String],
      required: false,
      default: null
    },
    // Find better prop name??
    current: {
      type: Array,
      required: true
    },
    // Find better prop name??
    previous: {
      type: Array,
      required: true
    }
  },
  computed: {
    isNegative() {
      return this.percentage < 0 ? true : false;
    },
    isPositive() {
      return this.percentage > 0 ? true : false;
    },
    percentage() {
      if (this.current && this.previous) {
        try {
          const old = this.previous
            .map(el => {
              return el[1];
            })
            .reduce((a, b) => Number(a) + Number(b), 0);
          const current = this.current
            .map(el => {
              return el[1];
            })
            .reduce((a, b) => Number(a) + Number(b), 0);
          const decreaseValue = old - current;
          if (old === current) {
            return null;
          } else if (old === 0) {
            return Math.abs(100);
          } else if (old > current) {
            return -Math.abs((decreaseValue / old) * 100).toFixed(1)
          } else if (current > old) {
            return Math.abs((decreaseValue / old) * 100).toFixed(1);
          }
        } catch (error) {
          return null;
        }
      }
      return null;
    }
  }
};
</script>
