<template>
  <div>
    <v-container fluid grid-list-xl class="pl-0 pr-0" v-if="!isLoading">
      <v-layout wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Click</div>
              </div>
            </v-card-title>
            <v-card-text>
              <monthly-chart v-model="formattedStats.clicks"></monthly-chart>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Unique clicks</div>
              </div>
            </v-card-title>
            <v-card-text>
              <monthly-chart
                v-model="formattedStats.unique_clicks"
              ></monthly-chart>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Sales</div>
              </div>
            </v-card-title>
            <v-card-text>
              <monthly-chart v-model="formattedStats.sales"></monthly-chart>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Commission</div>
              </div>
            </v-card-title>
            <v-card-text>
              <monthly-chart
                v-model="formattedStats.commission"
              ></monthly-chart>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Revenue (brands)</div>
              </div>
            </v-card-title>
            <v-card-text>
              <monthly-chart v-model="formattedStats.revenue"></monthly-chart>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 v-if="isInfluencer || isBusiness">
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Conversion rate</div>
                <!--<span class="grey&#45&#45text">Seneste 6 måneder</span>-->
              </div>
            </v-card-title>
            <v-card-text>
              <monthly-chart
                v-model="formattedStats.conversion"
              ></monthly-chart>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-flex class="pt-2" v-else>
      <v-flex xs12 v-for="item in 6" :key="item" class="mb-3">
        <v-card class="p-2 w-100">
          <v-skeleton-loader
            type="card-heading"
            class="mb-2"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="rounded"
            width="100%"
            type="image"
          ></v-skeleton-loader>
        </v-card>
      </v-flex>
    </v-flex>
  </div>
</template>
<script>
import local_numbers from "@/helpers/filters/local_numbers";
import MonthlyChart from "../../charts/MonthlyChart";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { MonthlyChart },
  filters: { local_numbers },
  data: () => ({
    stats: [],
    isLoading: true
  }),
  computed: {
    ...mapGetters("core/auth", [
      "isModerator",
      "isBusiness",
      "isInfluencer",
      "isAgency"
    ]),
    businessId() {
      return this.$route.query.business_uuid;
    },
    formattedStats() {
      let final = {
        clicks: [],
        unique_clicks: [],
        sales: [],
        revenue: [],
        commission: [],
        conversion: []
      };
      let stats = this.cloneDeep(this.stats).reverse();
      stats.forEach(item => {
        final.clicks.push([item.date, item.clicks]);
        final.unique_clicks.push([item.date, item.unique_clicks]);
        final.sales.push([item.date, item.sales]);
        final.revenue.push([item.date, item.value]);
        final.commission.push([item.date, item.commission]);
        if (this.isInfluencer || this.isBusiness) {
          final.conversion.push([item.date, item.conversion]);
        }
      });
      return final;
    }
  },
  methods: {
    ...mapActions("core", ["getStats"]),
    getMonthlyStats() {
      let params = {
        start_date: this.$moment()
          .subtract(24, "months")
          .startOf("month")
          .format("YYYY-MM-DD"),
        end_date: this.$moment()
          .utc()
          .format("YYYY-MM-DD"),
        starting_at: this.$moment()
          .subtract(24, "months")
          .startOf("month")
          .format("YYYY-MM-DD hh:mm:ss"),
        ending_at: this.$moment()
          .utc()
          .format("YYYY-MM-DD hh:mm:ss"),
        group: ["month"]
      };
      if (this.isAgency && this.businessId) {
        params.business_uuid = this.businessId;
      }
      this.getStats(params).then(
        stats => {
          this.stats = stats;
          this.isLoading = false;
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  watch: {
    $route() {
      if (this.isAgency) {
        this.isLoading = true;
        this.getMonthlyStats();
      }
    }
  },
  created() {
    this.getMonthlyStats();
  }
};
</script>
