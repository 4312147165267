<template>
  <div >
    <v-container fluid grid-list-xl class="pl-0 pr-0" v-if="!isLoading">
      <v-layout wrap>
        <v-flex lg4 md6 xs12>
          <stat-widget
            icon="fal fa-hand-point-up"
            title="Clicks"
            :total="total.clicks | local_numbers"
            :predicted="predicted.clicks | local_numbers"
            :current="formattedData.clicks"
            :previous="formattedPreviousData.clicks"
          ></stat-widget>
        </v-flex>

        <v-flex lg4 md6 xs12>
          <stat-widget
            icon="fal fa-fingerprint"
            title="Unique clicks"
            :total="total.unique_clicks | local_numbers"
            :predicted="predicted.unique_clicks | local_numbers"
            :current="formattedData.unique_clicks"
            :previous="formattedPreviousData.unique_clicks"
            :cpc="total.cpc"
          ></stat-widget>
        </v-flex>

        <v-flex lg4 md6 xs12>
          <stat-widget
            icon="fal fa-shopping-basket"
            title="Sales"
            :total="total.sales | local_numbers"
            :predicted="predicted.sales | local_numbers"
            :conversion="total.conversion + '%'"
            :current="formattedData.sales"
            :previous="formattedPreviousData.sales"
          ></stat-widget>
        </v-flex>

        <v-flex lg4 md6 xs12>
          <stat-widget
            icon="fal fa-money-bill-wave"
            title="Commission"
            :total="total.commission | local_numbers"
            :predicted="predicted.commission | local_numbers"
            :current="formattedData.commission"
            :previous="formattedPreviousData.commission"
          ></stat-widget>
        </v-flex>

        <v-flex lg4 md6 xs12>
          <stat-widget
            icon="fal fa-coins"
            title="Revenue"
            :total="total.revenue | local_numbers"
            :predicted="predicted.revenue | local_numbers"
            :current="formattedData.revenue"
            :previous="formattedPreviousData.revenue"
            :roi="total.roi"
          ></stat-widget>
        </v-flex>
        <v-flex xs12 sm6 v-if="!isInfluencer && topInfluencers.length > 0">
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Top influencers</div>
                <!--<span class="grey&#45&#45text">Influencers der har lavet flest salg denne måned</span>-->
              </div>
            </v-card-title>
            <v-card-text>
              <v-data-table
                hide-default-footer
                :headers="[
                  { text: 'Influencer', value: 'influencer.user.first_name' },
                  { text: 'Clicks', value: 'clicks' },
                  { text: 'Sales', value: 'sales' }
                ]"
                :items="topInfluencers"
                :items-per-page="10000"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.influencer.uuid">
                      <td>
                        <router-link
                          :to="{
                            name: 'influencer',
                            params: { id: item.influencer.uuid }
                          }"
                        >
                          <span v-if="item.influencer.user">
                            {{ item.influencer.user.first_name }}
                            {{ item.influencer.user.last_name }}
                          </span>
                          <span v-else>
                            Unknown
                          </span>
                        </router-link>
                      </td>
                      <td>{{ item.clicks }}</td>
                      <td>{{ item.sales }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex
          v-if="(isModerator || isInfluencer) && topBusinesses.length > 0"
          xs12
          sm6
        >
          <v-card>
            <v-card-title>
              <div>
                <div class="headline">Top brands</div>
                <!--<span class="grey&#45&#45text">Virksomheder der har lavet flest salg denne måned</span>-->
              </div>
            </v-card-title>
            <v-card-text>
              <v-data-table
                hide-default-footer
                :headers="[
                  { text: 'Brand', value: 'business.display_name' },
                  { text: 'Clicks', value: 'clicks' },
                  { text: 'Sales', value: 'sales' }
                ]"
                :items="topBusinesses"
                :items-per-page="10000"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.business.uuid">
                      <td>
                        <router-link
                          :to="{
                            name: 'business',
                            params: { id: item.business.uuid }
                          }"
                        >
                          {{ item.business.display_name }}
                        </router-link>
                      </td>
                      <td>{{ item.clicks }}</td>
                      <td>{{ item.sales }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-flex class="d-flex flex-wrap pt-2" v-else>
      <v-flex lg4 md6 xs12 v-for="(item, k) in 6" :key="`a-${k}`">
        <v-card class="p-2 m-2">
          <v-skeleton-loader
            type="list-item-avatar"
            class="mb-3"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="rounded"
            width="100%"
            type="image"
          ></v-skeleton-loader>
        </v-card>
      </v-flex>
      <v-flex lg6 md6 xs12 v-for="(item, k) in 2" :key="`b-${k}`">
        <v-card class="p-2 m-2">
          <v-skeleton-loader type="heading" class="mb-3"></v-skeleton-loader>
          <v-skeleton-loader
            class="rounded"
            width="100%"
            type="table-tbody"
          ></v-skeleton-loader>
        </v-card>
      </v-flex>
    </v-flex>
  </div>
</template>
<script>
import local_numbers from "@/helpers/filters/local_numbers";
import StatWidget from "../../cards/widgets/StatWidget";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { StatWidget },
  filters: { local_numbers },
  props: {},
  data: () => ({
    dailyStats: [],
    previousDailyStats: [],
    topInfluencers: [],
    topBusinesses: [],
    isLoading: true,
    firstLoad: true
  }),
  computed: {
    ...mapGetters("core/auth", [
      "isModerator",
      "isBusiness",
      "isInfluencer",
      "isAgency"
    ]),
    businessId() {
      return this.$route.query.business_uuid;
    },
    formattedData() {
      let final = {
        clicks: [],
        unique_clicks: [],
        sales: [],
        revenue: [],
        commission: []
      };

      // Loop to create chart data
      this.dailyStats.forEach(item => {
        let dateTime = this.$moment.utc(item.date, "YYYY-MM-DD");
        final.clicks.push([dateTime.format(), item.clicks]);
        final.unique_clicks.push([dateTime.format(), item.unique_clicks]);
        final.sales.push([dateTime.format(), item.sales]);
        final.revenue.push([dateTime.format(), item.value]);
        final.commission.push([dateTime.format(), item.commission]);
      });
      return final;
    },
    formattedPreviousData() {
      let final = {
        clicks: [],
        unique_clicks: [],
        sales: [],
        revenue: [],
        commission: []
      };

      // Loop to create chart data
      this.previousDailyStats.forEach(item => {
        let dateTime = this.$moment.utc(item.date, "YYYY-MM-DD");
        final.clicks.push([dateTime.format(), item.clicks]);
        final.unique_clicks.push([dateTime.format(), item.unique_clicks]);
        final.sales.push([dateTime.format(), item.sales]);
        final.revenue.push([dateTime.format(), item.value]);
        final.commission.push([dateTime.format(), item.commission]);
      });
      return final;
    },
    total() {
      let final = {
        clicks: 0,
        unique_clicks: 0,
        sales: 0,
        revenue: 0,
        commission: 0,
        conversion: 0,
        roi: 0,
        cpc: 0
      };
      this.dailyStats.forEach(function(item) {
        final.clicks += parseInt(item.clicks);
        final.unique_clicks += parseInt(item.unique_clicks);
        final.sales += parseInt(item.sales);
        final.revenue += parseFloat(item.value);
        final.commission += parseFloat(item.commission);
      });
      final.revenue = Math.round(final.revenue * 100) / 100;
      final.commission = Math.round(final.commission * 100) / 100;
      if (final.sales && final.clicks) {
        final.conversion =
          Math.round((final.sales / final.unique_clicks) * 100 * 100) / 100;
      }
      if (final.commission && final.unique_clicks) {
        final.roi =
          (Number(final.revenue) - Number(final.commission)) /
          Number(final.commission);
        final.cpc = Number(final.commission) / Number(final.unique_clicks);
      }
      return final;
    },
    predicted() {
      let daysInMonth = parseInt(this.$moment().daysInMonth());
      let elapsedDays = this.$moment()
        .utc()
        .format("DD");
      elapsedDays = parseInt(elapsedDays); // Convert to int so we can calculate
      let avgClick = this.total.clicks / elapsedDays;
      let avgUniqueClicks = this.total.unique_clicks / elapsedDays;
      let avgSales = this.total.sales / elapsedDays;
      let avgRevenue = this.total.revenue / elapsedDays;
      let avgCommission = this.total.commission / elapsedDays;
      return {
        clicks: Math.round(daysInMonth * avgClick),
        unique_clicks: Math.round(daysInMonth * avgUniqueClicks),
        sales: Math.round(daysInMonth * avgSales),
        revenue: Math.round(daysInMonth * avgRevenue),
        commission: Math.round(daysInMonth * avgCommission)
      };
    }
  },
  methods: {
    ...mapActions("core", [
      "getStats",
      "getDashboardBusinessStats",
      "getDashboardInfluencerStats"
    ]),
    getDailyStats() {
      let params = {
        start_date: this.$moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end_date: this.$moment()
          .utc()
          .format("YYYY-MM-DD"),
        group: ["date"]
      };
      if (this.isAgency && this.businessId) {
        params.business_uuid = this.businessId;
      }
      this.getStats(params).then(
        stats => {
          this.dailyStats = stats;
        },
        error => {
          console.log(error);
        }
      );
      const startDate=  this.$moment()
          .utc()
          .startOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD");
      const endDate = this.$moment()
          .utc()
          .subtract(1, "month")
          .format("YYYY-MM-DD");
      const lastYearStart = this.$moment()
        .utc()
        .startOf("month")
        .subtract(12, "month")
        .format("YYYY-MM-DD");
      const lastYearEnd = this.$moment()
        .utc()
        .subtract(12, "month")
        .format("YYYY-MM-DD");
      params = {
        start_date: this.isModerator ? lastYearStart : startDate,
        end_date: this.isModerator ? lastYearEnd : endDate,
        group: ["date"]
      };
      if (this.isAgency && this.businessId) {
        params.business_uuid = this.businessId;
      }
      this.getStats(params).then(
        response => {
          this.previousDailyStats = response;
        },
        error => {
          console.log(error);
        }
      );
      this.isLoading = false;
    },
    getTopInfluencers() {
      const start = this.$moment()
        .utc()
        .startOf("month")
        .format("YYYY-MM-DD");
      const end = this.$moment()
        .utc()
        .format("YYYY-MM-DD");
      let params = {
        start_date: start,
        end_date: end,
        group: ["influencer"],
        order: ["conversions", "clicks"],
        limit: 10
      };
      if (this.isAgency && this.businessId) {
        params.business_uuid = this.businessId;
      }
      this.getDashboardInfluencerStats(params).then(
        response => {
          this.topInfluencers = response.data;
          this.isLoading = false;
        },
        error => {
          console.log(error);
        }
      );
    },
    getTopBusinesses() {
      const start = this.$moment()
        .utc()
        .startOf("month")
        .format("YYYY-MM-DD");
      const end = this.$moment()
        .utc()
        .format("YYYY-MM-DD");
      let params = {
        start_date: start,
        end_date: end,
        group: ["business"],
        order: ["conversions"],
        limit: 10
      };

      this.getDashboardBusinessStats(params).then(
        response => {
          this.topBusinesses = response.data;
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  watch: {
    // watch
    $route() {
      if (this.isAgency) {
        this.isLoading = true;
        this.getDailyStats();
        this.getTopInfluencers();
        if (this.isModerator || this.isInfluencer) {
          this.getTopBusinesses();
        }
      }
    }
  },
  mounted() {
    if (this.isBusiness || this.isAgency) {
      this.getDailyStats();
      this.getTopInfluencers();
    }
    if (this.isModerator || this.isInfluencer) {
      this.getTopBusinesses();
      this.getDailyStats();
      this.getTopInfluencers();
    }
    this.firstLoad = false
  }
};
</script>
